import { currencyOptions } from '@/config/branchSetting'
import store from '@/store'
import { computed } from 'vue'
import { get } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'

export const useCurrency = () => {
  const isOverseasShop = computed(() => get(store.state, 'org.isOverseasShop'))
  const currency = computed(() => get(store.state, 'shop.shopConfig.currency'))
  const displayCurrency = computed(() => {
    return i18n.t(get(currencyOptions[currency.value], 'title'))
  })
  const displayCurrencySymbol = computed(() => {
    if (!isOverseasShop.value) return ''
    return get(currencyOptions[currency.value], 'symbol', '$')
  })
  return {
    displayCurrency,
    displayCurrencySymbol,
  }
}
